import React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  Keyboard,
  Platform,
} from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import PropTypes from 'prop-types';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';

export default class ImageUtils extends React.Component {
  // コンストラクタ
  constructor(props) {
    super(props);
  }

  // カメラ起動イベントハンドラ
  openCamera = async (onSend) => {
    // カメラへのアクセス許可ダイアログ表示
    const permissionResult = await ImagePicker.requestCameraPermissionsAsync();
    // アクセス許可確認
    if (permissionResult.granted === false) {
      alert('カメラへのアクセスを許可してください');
      return;
    }

    // カメラ表示
    const result = await ImagePicker.launchCameraAsync({
      allowsEditing: false,
      aspect: [4, 3],
    });
    if (!result.cancelled) {
      onSend([{ image: result.uri }]);
      console.log(result.uri);
    }
  };

  // アルバム表示イベントハンドラ
  openAlbum = async (onSend) => {
    // アルバムへのアクセス許可ダイアログ表示
    const permissionResult =
      await ImagePicker.requestMediaLibraryPermissionsAsync();
    // アクセス許可確認
    if (permissionResult.granted === false) {
      alert('アルバムへのアクセスを許可してください');
      return;
    }

    // アルバム表示
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: [4, 3],
    });
    if (!result.cancelled) {
      onSend([{ image: result.uri }]);
      // console.log(result.uri);
    }
  };

  onActionsPress = () => {
    Keyboard.dismiss();
    const options = ['カメラ撮影', 'アルバムから選択', 'キャンセル'];
    const cancelButtonIndex = options.length - 1;
    this.context.actionSheet().showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      async (buttonIndex) => {
        const { onSend } = this.props;
        switch (buttonIndex) {
          case 0:
            this.openCamera(onSend);
            return;
          case 1:
            this.openAlbum(onSend);
            return;
          default:
            return;
        }
      }
    );
  };
  render() {
    return (
      <TouchableOpacity style={styles.container} onPress={this.onActionsPress}>
        <FontAwesome5 style={styles.buttonIcon} name="image" size={22} />
      </TouchableOpacity>
    );
  }
}
// コンテキスト
ImageUtils.contextTypes = { actionSheet: PropTypes.func };

const styles = StyleSheet.create({
  container: {
    //width: 26,//26,
    //height: 26,
    //marginLeft: 40,
    //marginBottom: 10,
    flex: 1,
  },
  buttonIcon: {
    color: 'white',
    marginTop: 0,
    textAlign: 'center',
  },
});

// blobに変換
ImageUtils.base64toBlob = (base64, contentType) => {
  const bin = atob(base64.replace(/^.*,/, ''));
  const buffer = new Uint8Array(bin.length).map((_, i) => bin.charCodeAt(i));
  try {
    return new Blob([buffer.buffer], {
      type: contentType,
    });
  } catch (e) {
    return false;
  }
};

/**
 * webではImageUtils.jsdで取得されるuriがbase64で取得されるので処理を分ける
 *
 * @param {string|base64} image
 * @param {string} send_id
 * @returns {object} {fileName, fileType, fileExtension}
 */
ImageUtils.getPostImageOptions = (image, send_id) => {
  let fileName;
  let fileType;
  let fileExtension;

  if (Platform.OS !== 'web') {
    fileName = image.split('/').pop().split('\\').pop();
    fileExtension = fileName.split('.').pop();
  } else {
    let content = image
      .toString()
      .slice(image.indexOf(':') + 1, image.indexOf(';'));
    fileExtension = content.split('/').pop();
    // ファイル名は取得できないのでsend_idを使用
    fileName = send_id + '.' + fileExtension;
  }
  fileType = fileExtension.toLowerCase() == 'png' ? 'image/png' : 'image/jpeg';

  return {
    fileName: fileName,
    fileType: fileType,
    fileExtension: fileExtension,
  };
};
