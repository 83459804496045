import React from 'react';
import { View, Text, Button, StyleSheet, FlatList } from 'react-native';
import { ListItem, Avatar } from 'react-native-elements';
import * as SecureStore from 'expo-secure-store';
import axios from 'axios';
import moment from 'moment';
import Constants from 'expo-constants';
import { AuthContext } from '../utils/ContextUtils';

export default class HomeList extends React.Component {
  constructor(props) {
    console.log('==================== HomeList.constructor start.');
    super(props);
    this.state = { data: [], isFetching: false, fields: [] };
    this.willFocusSubscription = null;
    console.log('==================== HomeList.constructor end.');
  }

  componentDidMount() {
    console.log('==================== HomeList.componentDidMount start.');
    this.getFieldsList();
    this.getDataloggers();
    this.willFocusSubscription = this.props.navigation.addListener(
      'willFocus',
      () => {
        this.getFieldsList();
        this.getDataloggers();
      }
    );
    console.log('==================== HomeList.componentDidMount end.');
  }

  componentWillUnmount() {
    console.log('==================== HomeList.componentWillUnmount start.');
    this.willFocusSubscription.remove();
    console.log('==================== HomeList.componentWillUnmount end.');
  }

  getDataloggers() {
    console.log('==================== HomeList.getDataloggers start.');
    axios
      .get(Constants.manifest.extra.apiUrl + '/api/dataloggers', {
        params: { user_id: this.context.user.id },
        headers: { Authorization: 'Bearer ' + this.context.user.token },
      })
      .then((response) => {
        this.setState({ data: response.data });
        this.setState({ isFetching: false });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isFetching: false });
      });
    console.log('==================== HomeList.getDataloggers end.');
  }

  // 区画のリスト
  getFieldsList() {
    console.log('==================== HomeList.getFieldList start.');
    axios
      .get(Constants.manifest.extra.apiUrl + '/api/fields', {
        params: { user_id: this.context.user.id },
        headers: { Authorization: 'Bearer ' + this.context.user.token },
      })
      .then((response) => {
        this.setState({ fields: response.data });
        this.setState({ isFetching: false });
      })
      .catch((error) => {
        console.error(error);
      });
    console.log('==================== HomeList.getFieldList end.');
  }

  createItemView(field) {
    let sensors = this.state.data;
    let item = null;
    Object.keys(sensors).forEach((key) => {
      let sensor = sensors[key];
      if (sensor.farm.id == field.farm.id) {
        sensor.sensors_has_fields.map((sensorsHasField) => {
          if (sensorsHasField.field_id == field.id) {
            item = sensor;
            return true;
          }
        });
      }
    });
    if (item == null) {
      return;
    }

    let avatarUrl =
      Constants.manifest.extra.apiUrl + '/images/default_no_image.png';
    let titleDatetime = '';
    let subTitleCondition = '';
    let subTitlePlace = item.farm.name + ' - ' + field.name;
    let imageDatetime = '';

    if (item.tmp_dataloggers.length > 0) {
      let tmp = item.tmp_dataloggers[0];
      titleDatetime = moment(tmp.measure_at).format('YYYY/MM/DD HH:mm');
      subTitleCondition +=
        '温度：' + (Math.round(tmp.value * 10) / 10).toFixed(1) + ' ℃\n';
    }
    if (item.hum_dataloggers.length > 0) {
      let hum = item.hum_dataloggers[0];
      if (titleDatetime.length <= 0) {
        titleDatetime = moment(hum.measure_at).format('YYYY/MM/DD HH:mm');
      }
      subTitleCondition +=
        '湿度：' + (Math.round(hum.value * 10) / 10).toFixed(1) + ' ％\n';
    }
    if (item.img_dataloggers.length > 0) {
      let img = item.img_dataloggers[0];
      imageDatetime = moment(img.measure_at).format('HH:mm');
      // データロガーの画像があればアバターを置き換える
      avatarUrl = Constants.manifest.extra.apiUrl + img.picture.thumb.url;
    }

    return (
      <ListItem bottomDivider>
        <View>
          <Avatar
            size="xlarge"
            source={{ uri: avatarUrl }}
            imageProps={{ resizeMode: 'contain' }}
          />
          {/* <View>
            <ListItem.Subtitle style={styles.datetimeImage}>{imageDatetime}</ListItem.Subtitle>
          </View> */}
        </View>
        <View>
          <ListItem.Content style={styles.message}>
            {/* <ListItem.Subtitle style={styles.datetime}>{titleDatetime}</ListItem.Subtitle> */}
            <ListItem.Subtitle style={styles.place}>
              {subTitlePlace}
            </ListItem.Subtitle>
            <ListItem.Subtitle style={styles.condition}>
              {subTitleCondition}
            </ListItem.Subtitle>
          </ListItem.Content>
          {/* <View>
            <ListItem.Subtitle style={styles.place}>{subTitlePlace}</ListItem.Subtitle>
          </View> */}
        </View>
      </ListItem>
    );
  }

  keyExtractor = (item, index) => item.id.toString();
  renderItem = ({ item }) => <View>{this.createItemView(item)}</View>;

  onRefresh() {
    console.log('==================== HomeList.onRefresh start.');
    this.setState({ isFetching: true });
    this.getDataloggers();
    console.log('==================== HomeList.onRefresh end.');
  }

  render() {
    return (
      <View style={styles.container}>
        <FlatList
          keyExtractor={this.keyExtractor}
          data={this.state.fields}
          renderItem={this.renderItem}
          onRefresh={() => this.onRefresh()}
          refreshing={this.state.isFetching}
        />
      </View>
    );
  }
}

HomeList.contextType = AuthContext;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  rightContainer: {
    flex: 1,
  },
  message: {
    flex: 2,
    justifyContent: 'flex-start',
  },
  place: {
    //marginBottom: 5,
    fontSize: 12,
    textAlign: 'right',
  },
  condition: {
    marginTop: 20,
    fontSize: 20,
  },
  datetime: {
    fontSize: 18,
  },
  datetimeImage: {
    fontSize: 10,
    textAlign: 'right',
  },
});
